.header {
	display: grid;
	grid-template-columns: 90px 1fr;
	grid-template-rows: 72px;
}
.logo {
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #215294;
	border-bottom: 1px solid #4770a7;
	color: rgba(255, 255, 255, 1);
	cursor: pointer;
}
.logo svg {
	margin-top: 12px;
}
.logoTitle {
	margin-top: 6px;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
}
.contentHeader {
	//background: white;
	padding: 0 18px;
	border-bottom: 1px solid rgba(237, 241, 247, 1);
	max-height: 72px;
	display: flex;
	justify-content: space-between;
}
.text {
	display: flex;
	align-items: center;
	margin-top: 8px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 17px;
}
.text svg {
	margin-right: 6px;
}
.breadCrumbs {
	display: flex;
	align-items: center;
	margin-top: 3px;
	list-style: none;
	color: rgba(0, 0, 0, 0.85);
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 38px;
}
.breadCrumbs li:not(:last-child) {
	color: rgba(0, 0, 0, 0.45);
	cursor: pointer;
}
.breadCrumbs li:not(:last-child):after {
	content: '/';
	margin: 0 13px;
}
@media screen and (max-width: 1279px) {
	.breadCrumbs {
		font-size: calc(10px + 0.5vw);
	}
}
@media screen and (max-width: 767px) {
	.content {
		overflow: hidden;
	}
	.breadCrumbs {
		font-size: 12px;
		overflow-x: auto;
		white-space: nowrap;
	}
}
@media screen and (max-width: 600px) {
	.text {
		font-size: 11px;
	}
}
